<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="6">
                        <ValidatableTextField v-model:form="form" type="number" label="Public Price" :field="'public_price'" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" type="number" label="Payout" min="0" :field="'payout_price'" />
            </v-col>
            <v-col cols="6">
                <ReadOnlyTextField :hide-details="false" :value="form.currency" label="Currency" disabled />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.bundle_id" v-model:form="form" :field-name="'bundle_id'" :items="bundles" :loading="fetchingBundles" label="Bundle" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository.ts';
import type { ITransaction } from '@app/purchase/transaction/models/Transaction.ts';
import { useBundleRepository } from '@app/purchase/bundle/repositories/useBundleRepository.ts';

const props = defineProps({
    item: {
        type: Object as PropType<ITransaction>,
        required: true,
    },
});

const update = useTransactionRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);

const { data: bundles, processing: fetchingBundles } = useBundleRepository().query.listAllowedFor(form.steward_id);
</script>
