import { useRoute } from '@shared/composables/useRoute';
import { Bundle, type IBundle } from '@app/purchase/bundle/models/Bundle';
import type { FormOptions } from '@shared/types/Form';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useForm } from '@shared/composables/useForm';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import { usePayload } from '@shared/composables/usePayload';
import { useFetchListData } from '@shared/composables/useFetchListData.ts';

export function useBundleRepository<T extends IBundle = IBundle>() {
    const command = () => {
        const store = (data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                Bundle.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>(data)).submit(options);
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                {
                    url: useRoute().build(Bundle.routes().update, {
                        id,
                    }),
                    method: Bundle.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(data,  {
                        only: ['name', 'steward_id', 'status', 'date'],
                    }),
                ).submit(options);
            }

            return { form, execute };
        };

        const destroy = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Bundle.routes().destroy, {
                        id,
                    }),
                    method: Bundle.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        return {
            store,
            update,
            destroy,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(Bundle.routes().show, { id }) };
        };

        const listAllowedFor = (stewardId: string) => {
            return useFetchListData<IBundle>(useRoute().build(Bundle.routes().index,
                {
                    'search': 'steward_id:' + stewardId,
                    'searchFields': 'steward_id:=',
                    'searchJoin': 'and',
                }), 'appSection@bundle::IndexBundlesPage')
        }

        return {
            show,
            listAllowedFor,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
