import { clientConfig } from '@app/purchase/client/configs/clientConfig';
import { blockConfig } from '@app/purchase/block/configs/blockConfig';
import { approvalAuthorityConfig } from '@app/land-hold/approval-authority/configs/approvalAuthorityConfig.ts';
import { certifierConfig } from '@app/land-hold/certifier/configs/certifierConfig.ts';
import { countryConfig } from '@app/location/country/configs/countryConfig';
import { inventoryConfig } from '@app/land-hold/inventory/configs/inventoryConfig';
import { inventoryQueueViewConfig } from '@app/land-hold/inventory/configs/inventoryQueueViewConfig';
import { inventoryViewConfig } from '@app/land-hold/inventory/configs/inventoryViewConfig';
import { propertyConfig } from '@app/land-hold/property/configs/propertyConfig';
import { landUseFactorConfig } from '@app/land-hold/land-use-factor/configs/landUseFactorConfig';
import { landUseTypeConfig } from '@app/land-hold/land-use-type/configs/landUseTypeConfig';
import { ownerConfig } from '@app/land-hold/owner/configs/ownerConfig';
import { partitionConfig } from '@app/land-hold/partition/configs/partitionConfig';
import { projectConfig } from '@app/land-hold/project/configs/projectConfig.ts';
import { stateConfig } from '@app/location/state/configs/stateConfig';
import { stewardConfig } from '@app/land-hold/steward/configs/stewardConfig';
import { transactionConfig } from '@app/purchase/transaction/configs/transactionConfig';
import { publicTransactionConfig } from '@app/purchase/transaction/configs/publicTransactionConfig';
import { userConfig } from '@app/user/configs/userConfig';
import { inventoryTypeConfig } from '@app/land-hold/inventory-type/configs/inventoryTypeConfig';
import { transactionTypeConfig } from '@app/purchase/transaction-type/configs/transactionTypeConfig';
import { bundleConfig } from '@app/purchase/bundle/configs/bundleConfig.ts';
import type { Configs } from '@shared/types/Config';
import { payoutConfig } from '@app/purchase/payout/configs/payoutConfig.ts';

export function useConfig(): Configs {
    return {
        client: clientConfig,
        block: blockConfig,
        approvalAuthority: approvalAuthorityConfig,
        certifier: certifierConfig,
        country: countryConfig,
        inventory: inventoryConfig,
        inventoryType: inventoryTypeConfig,
        inventoryQueueView: inventoryQueueViewConfig,
        inventoryView: inventoryViewConfig,
        landUseFactor: landUseFactorConfig,
        landUseType: landUseTypeConfig,
        owner: ownerConfig,
        partition: partitionConfig,
        project: projectConfig,
        property: propertyConfig,
        state: stateConfig,
        steward: stewardConfig,
        transaction: transactionConfig,
        publicTransaction: publicTransactionConfig,
        transactionType: transactionTypeConfig,
        user: userConfig,
        bundle: bundleConfig,
        payout: payoutConfig,
    };
}
