import { useForm } from '@shared/composables/useForm';
import { useRoute } from '@shared/composables/useRoute';
import { usePayload } from '@shared/composables/usePayload';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import { type ITransaction, Transaction } from '@app/purchase/transaction/models/Transaction';
import type { FormOptions } from '@shared/types/Form';
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';

export function useTransactionRepository<T extends ITransaction = ITransaction>() {
    const command = () => {
        const store = (data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                Transaction.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(data, {
                        exclude: Transaction.create(data).hidden(),
                    }),
                ).submit(options);
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                {
                    url: useRoute().build(Transaction.routes().update, {
                        id,
                    }),
                    method: Transaction.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(data, {
                        nullable: ['public_price', 'payout_price', 'bundle_id']
                    }),
                ).submit(options);
            }

            return { form, execute };
        };

        const fulfill = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Transaction.routes().fulfill, {
                        id,
                    }),
                    method: Transaction.routes().fulfill.method,
                },
                options,
            );
            function execute(options?: FormOptions) {
                form.submit(options);
            }
            return { form, execute };
        };

        const destroy = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Transaction.routes().destroy, {
                        id,
                    }),
                    method: Transaction.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        return {
            store,
            update,
            fulfill,
            destroy,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(Transaction.routes().show, { id }) };
        };

        const showPublic = (id: string) => {
            return { endpoint: useRoute().build(PublicTransaction.routes().show, { id }) };
        };

        return {
            show,
            showPublic,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
