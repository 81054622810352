import { Block, type IBlock } from '../models/Block';
import { type ITransaction } from '@app/purchase/transaction/models/Transaction';
import type { IInventory } from '@app/land-hold/inventory/models/Inventory';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';
import type { IndexRoute, ShowRoute } from '@shared/types/Route';

export enum AvailableIncludes {
    Transaction = 'transaction',
    Inventory = 'inventory',
}

export interface TransactionInclude {
    transaction: SingleObjectInclude<ITransaction>;
}
export interface InventoryInclude {
    inventory: SingleObjectInclude<IInventory>;
}
export type AllBlockIncludes = IBlock & TransactionInclude & InventoryInclude;
export type IndexBlockIncludes = IBlock & TransactionInclude & InventoryInclude;
export type ShowBlockIncludes = IndexBlockIncludes;

export function routes(): {
    index: IndexRoute<'blocks.index', IndexBlockIncludes>;
    show: ShowRoute<'blocks.show', ShowBlockIncludes>;
} {
    return {
        index: {
            method: 'get',
            name: 'blocks.index',
            params: { include: [AvailableIncludes.Transaction, AvailableIncludes.Inventory], orderBy: 'created_at', sortedBy: 'desc' },
            datatableHeaders: () => Block.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'blocks.show', params: { include: [AvailableIncludes.Transaction, AvailableIncludes.Inventory] }, page: usePage() },
    };
}
