import { Block, type IBlock } from '@app/purchase/block/models/Block';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type ITransaction, Transaction } from '@app/purchase/transaction/models/Transaction';
import type { ITransactionType } from '@app/purchase/transaction-type/models/TransactionType';
import type { IPartner } from '@app/purchase/partner/models/Partner';
import type { Include, MultiObjectInclude, PaginatedResponse, SingleObjectInclude, SingleObjectResponse } from '@shared/types/ApiResponse';
import { type IndexBlockIncludes, AvailableIncludes as BlockAvailableIncludes } from '@app/purchase/block/routes/routes';
import type { ICertifier } from '@app/land-hold/certifier/models/Certifier';
import type { IBundle } from '@app/purchase/bundle/models/Bundle.ts';

export const AvailableIncludes = {
    Blocks: 'blocks',
    TransactionType: 'transaction_type',
    Partner: 'partner',
    Certifier: 'certifier',
    Bundle: 'bundle',
} as const;

export type BlocksInclude = Include<typeof AvailableIncludes, 'blocks', IBlock, MultiObjectInclude<IBlock>>;
export type TransactionTypeInclude = Include<typeof AvailableIncludes, 'transaction_type', ITransactionType, SingleObjectInclude<ITransactionType>>;
export type PartnerInclude = Include<typeof AvailableIncludes, 'partner', IPartner, SingleObjectInclude<IPartner>>;
export type CertifierInclude = Include<typeof AvailableIncludes, 'certifier', ICertifier, SingleObjectInclude<ICertifier> | null>;
export type BundleInclude = Include<typeof AvailableIncludes, 'bundle', IBundle, SingleObjectInclude<IBundle> | null>;

export type AllTransactionIncludes = ITransaction & BlocksInclude & TransactionTypeInclude & PartnerInclude & CertifierInclude & BundleInclude;
export type IndexTransactionIncludes = ITransaction & TransactionTypeInclude & PartnerInclude & CertifierInclude & BundleInclude;
export type ShowTransactionIncludes = IndexTransactionIncludes;

export function routes(): {
    index: IndexRoute<'transactions.index', IndexTransactionIncludes>;
    show: ShowRoute<'transactions.show', ShowTransactionIncludes>;
    destroy: DestroyRoute<'transactions.destroy'>;
    create: CreateRoute<'transactions.create'>;
    store: StoreRoute<'transactions.store'>;
    edit: EditRoute<'transactions.edit'>;
    update: UpdateRoute<'transactions.update'>;
    blocksIndex: IndexRoute<
        'transactions.blocks.index',
        IndexBlockIncludes,
        {
            transaction: SingleObjectResponse<ITransaction>;
        } & PaginatedResponse<IndexBlockIncludes>
    >;
    fulfill: StoreRoute<'transactions.fulfill', ITransaction>;
} {
    return {
        index: { method: 'get', name: 'transactions.index', params: { include: [AvailableIncludes.TransactionType, AvailableIncludes.Partner, AvailableIncludes.Certifier, AvailableIncludes.Bundle], orderBy: 'created_at', sortedBy: 'desc' }, datatableHeaders: () => Transaction.headers(), page: usePage() },
        show: { method: 'get', name: 'transactions.show', params: { include: [AvailableIncludes.TransactionType, AvailableIncludes.Partner, AvailableIncludes.Certifier, AvailableIncludes.Bundle] }, page: usePage() },
        destroy: { method: 'delete', name: 'transactions.destroy', page: usePage() },
        create: { method: 'get', name: 'transactions.create', page: usePage() },
        store: { method: 'post', name: 'transactions.store', page: usePage() },
        edit: { method: 'get', name: 'transactions.edit', page: usePage() },
        update: { method: 'patch', name: 'transactions.update', page: usePage() },
        blocksIndex: { method: 'get', name: 'transactions.blocks.index', params: { include: [BlockAvailableIncludes.Transaction, BlockAvailableIncludes.Inventory] }, datatableHeaders: () => Block.headers(), page: usePage() },
        fulfill: { method: 'post', name: 'transactions.fulfill', page: usePage() },
    };
}
