<template>
    <DataTable
        title="Payouts"
        :headers="Payout.headers()"
        :model-class="Payout"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Payout',
            updateDialogBtnText: 'Update Payout',
            deleteDialogBtnText: 'Delete Payout',
            showCreateAction: false,
            showUpdateAction: true,
            showDeleteAction: false,
            showViewAction: true,
        }"
        :config="useConfig().payout"
    >
        <template #top-bar>
            <v-row justify="end">
                <v-col cols="auto">
                    <v-btn @click="useDownloadCsv('payouts', Payout.csvHeaders())">Download CSV</v-btn>
                </v-col>
            </v-row>
        </template>
        <template #update-action-dialog="{ item }">
            <UpdatePayoutDialog :item />
        </template>
        <template #custom-action="{ item }">
            <ShowButton :to="usePayoutRepository().query.show(item.id).endpoint" />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Payout } from '@app/purchase/payout/models/Payout';
import { useConfig } from '@shared/composables/useConfig';
import { usePayoutRepository } from '@app/purchase/payout/repositories/usePayoutRepository.ts';
import { useDownloadCsv } from '@shared/composables/useDownloadCsv.ts';

const page = Payout.routes().index.page;
</script>
