<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Payouts', to: useRoute().build(Payout.routes().index) }, { title: 'Payout Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.block.data.block_serial_code" label="Block Serial Code" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.transaction.data.transaction_code" label="Transaction Code" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.owner.data.name" label="Owner" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.status" label="Status" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.amount" label="Total" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.currency" label="Currency" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.block.data.carbon_block_tco2e" label="Carbon" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.price" label="Payout" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.note" label="Note" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.bundle?.data.name" label="Bundle" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.bundle?.data.status" label="Bundle Status" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyDateTextField :date="page.props.data.bundle?.data.date" label="Bundle Date" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.property.data.property_short_code" label="Property Code" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <GenericActionDialog confirm-btn-text="Update" title="Edit Payout" :disabled="false" @confirm="confirm($event)">
                        <template #activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" :loading="processing">Edit</v-btn>
                        </template>
                        <template #body>
                            <UpdatePayoutDialog :item="page.props.data" />
                        </template>
                    </GenericActionDialog>
                </v-card-actions>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { Payout } from '@app/purchase/payout/models/Payout';
import { useRoute } from '@shared/composables/useRoute';
import { useSubmitForm } from '@shared/composables/data-table/useSubmitForm.ts';

const page = Payout.routes().show.page;
const { confirm, processing } = useSubmitForm();
</script>
