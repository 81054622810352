import type { Include, SingleObjectInclude } from '@shared/types/ApiResponse';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type IPayout, Payout } from '@app/purchase/payout/models/Payout';
import type { IBlock } from '@app/purchase/block/models/Block';
import type { IOwner } from '@app/land-hold/owner/models/Owner.ts';
import type { IBundle } from '@app/purchase/bundle/models/Bundle.ts';
import type { IProperty } from '@app/land-hold/property/models/Property.ts';
import type { IInventory } from '@app/land-hold/inventory/models/Inventory.ts';
import type { ITransaction } from '@app/purchase/transaction/models/Transaction.ts';

export const AvailableIncludes = {
    Block: 'block',
    Transaction: 'transaction',
    Owner: 'owner',
    Bundle: 'bundle',
    Property: 'property',
    Inventory: 'inventory',
} as const;

type BlockInclude = Include<typeof AvailableIncludes, 'block', IBlock, SingleObjectInclude<IBlock>>;
type TransactionInclude = Include<typeof AvailableIncludes, 'transaction', ITransaction, SingleObjectInclude<ITransaction>>;
type OwnerInclude = Include<typeof AvailableIncludes, 'owner', IOwner, SingleObjectInclude<IOwner>>;
type BundleInclude = Include<typeof AvailableIncludes, 'bundle', IBundle, SingleObjectInclude<IBundle> | null>;
type PropertyInclude = Include<typeof AvailableIncludes, 'property', IProperty, SingleObjectInclude<IProperty>>;
type InventoryInclude = Include<typeof AvailableIncludes, 'inventory', IInventory, SingleObjectInclude<IInventory>>;

export type AllPayoutIncludes = IPayout & TransactionInclude & BlockInclude & OwnerInclude & BundleInclude & PropertyInclude & InventoryInclude;
export type IndexPayoutIncludes = IPayout & TransactionInclude & BlockInclude & OwnerInclude & BundleInclude & PropertyInclude & InventoryInclude;
export type ShowPayoutIncludes = IndexPayoutIncludes;

export function routes(): {
    index: IndexRoute<'payouts.index', IndexPayoutIncludes>;
    show: ShowRoute<'payouts.show', ShowPayoutIncludes>;
    destroy: DestroyRoute<'payouts.destroy'>;
    create: CreateRoute<'payouts.create'>;
    store: StoreRoute<'payouts.store'>;
    edit: EditRoute<'payouts.edit'>;
    update: UpdateRoute<'payouts.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'payouts.index',
            params: {
                include: [AvailableIncludes.Block, AvailableIncludes.Transaction, AvailableIncludes.Owner, AvailableIncludes.Bundle, AvailableIncludes.Property, AvailableIncludes.Inventory],
                orderBy: 'created_at',
                sortedBy: 'desc',
            },
            datatableHeaders: () => Payout.headers(),
            page: usePage(),
        },
        show: {
            method: 'get',
            name: 'payouts.show',
            params: {
                include: [AvailableIncludes.Block, AvailableIncludes.Transaction, AvailableIncludes.Owner, AvailableIncludes.Bundle, AvailableIncludes.Property, AvailableIncludes.Inventory],
            },
            page: usePage(),
        },
        destroy: { method: 'delete', name: 'payouts.destroy', page: usePage() },
        create: { method: 'get', name: 'payouts.create', page: usePage() },
        store: { method: 'post', name: 'payouts.store', page: usePage() },
        edit: { method: 'get', name: 'payouts.edit', page: usePage() },
        update: { method: 'patch', name: 'payouts.update', page: usePage() },
    };
}
