<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.status" v-model:form="form" :field-name="'status'" :items="Payout.statuses()" label="Status" />
            </v-col>
            <v-col cols="12">
                <ValidatableTextField v-model:form="form" label="Note" :field="'note'" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { Payout, type IPayout } from '@app/purchase/payout/models/Payout.ts';
import { usePayoutRepository } from '@app/purchase/payout/repositories/usePayoutRepository.ts';

const props = defineProps({
    item: {
        type: Object as PropType<IPayout>,
        required: true,
    },
});

const update = usePayoutRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);
</script>
