<template>
    <v-app-bar color="primary" density="default">
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" />
        <v-img src="@shared/assets/images/gesg-logo.png" max-width="200" min-width="200" class="mr-1" />
        <v-divider inset vertical />
        <v-list-item lines="two">
            <v-list-item-title>Carbono Biodiverso</v-list-item-title>
            <v-list-item-subtitle>Registry</v-list-item-subtitle>
        </v-list-item>

        <v-spacer />

        <v-btn variant="text" rounded="button" target="_blank" href="https://sierragorda.net/">How it works</v-btn>
        <v-tooltip text="Log out">
            <template #activator="{ props }">
                <v-btn v-bind="props" icon="mdi-logout" @click="logout" />
            </template>
        </v-tooltip>

        <!--        <v-menu>-->
        <!--            <template #activator="{ props }">-->
        <!--                <v-btn icon="mdi-dots-vertical" v-bind="props" />-->
        <!--                &lt;!&ndash;                <v-btn icon="mdi-dots-vertical" variant="text" />&ndash;&gt;-->
        <!--            </template>-->

        <!--            <v-list>-->
        <!--                <v-list-item v-for="(item, i) in items" :key="i">-->
        <!--                    <v-list-item-title>{{ item.title }}</v-list-item-title>-->
        <!--                </v-list-item>-->
        <!--            </v-list>-->
        <!--        </v-menu>-->
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" :color="theme.global.current.value.variables['sidebarSurface'] as string" :location="$vuetify.display.mobile ? 'bottom' : 'start'">
        <template #prepend>
            <v-list-item lines="two" :title="page.props.shared.auth.user.name" :subtitle="page.props.shared.auth.user.email" nav density="comfortable">
                <template #prepend>
                    <v-avatar size="x-large" density="compact">
                        <v-icon color="white" size="x-large">mdi-account-circle</v-icon>
                    </v-avatar>
                </template>
            </v-list-item>
        </template>

        <v-divider />

        <v-list v-model:opened="open" nav>
            <template v-for="(parent, i) in items">
                <v-list-item v-if="isEmptyArray(parent.children)" :key="parent.title + 'parent' + i" :to="parent.to" :prepend-icon="parent.icon" :active="parent.active" :title="parent.title" nav @click="activateItem(parent)" />
                <v-list-group v-else :key="parent.title + 'group'" :value="parent.title">
                    <template #activator="{ props }">
                        <v-list-item v-bind="props" :prepend-icon="parent.icon" :title="parent.title" />
                    </template>
                    <v-list-item v-for="(child, j) in parent.children" :key="child.title + 'child' + j" :to="child.to" :prepend-icon="child.icon" :title="child.title" :value="child.title" :active="child.active" nav @click="activateItem(child)" />

                    <!--                    Submenu inside another submenu example-->
                    <!--                    <v-list-group value="Admin">-->
                    <!--                        <template #activator="{ props }">-->
                    <!--                            <v-list-item v-bind="props" title="Admin"></v-list-item>-->
                    <!--                        </template>-->

                    <!--                        <v-list-item v-for="([title, icon], i) in admins" :key="i" :prepend-icon="icon" :title="title" :value="title"></v-list-item>-->
                    <!--                    </v-list-group>-->
                </v-list-group>
            </template>
        </v-list>

        <v-list-item subtitle="Settings" />
        <v-list nav>
            <v-list-item prepend-icon="mdi-account-circle" title="Account" :to="useRoute().build(User.routes().profile)" />
        </v-list>

        <template #append>
            <v-list nav>
                <v-list-item :style="publicRegistryFontStyle" prepend-icon="mdi-open-in-new" title="Public Registry" :href="useRoute().build(PublicTransaction.routes().index)" target="__blank" />
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
import { Client } from '@app/purchase/client/models/Client';
import { Transaction } from '@app/purchase/transaction/models/Transaction';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import { Property } from '@app/land-hold/property/models/Property';
import { Partition } from '@app/land-hold/partition/models/Partition';
import { Owner } from '@app/land-hold/owner/models/Owner';
import { LandUseFactor } from '@app/land-hold/land-use-factor/models/LandUseFactor';
import { LandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import { Steward } from '@app/land-hold/steward/models/Steward';
import { State } from '@app/location/state/models/State';
import { Country } from '@app/location/country/models/Country';
import { User } from '@app/user/models/User';
import { Block } from '@app/purchase/block/models/Block';
import { InventoryQueue } from '@app/land-hold/inventory/models/InventoryQueue';
import { useRoute } from '@shared/composables/useRoute';
import { useUtility } from '@shared/composables/useUtility';
import { TransactionType } from '@app/purchase/transaction-type/models/TransactionType';
import { InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import { useAuthenticationRepository } from '@app/authentication/repositories/useAuthenticationRepository';
import { useAnalyticsRepository } from '@app/analytic/repositories/useAnalyticsRepository';
import { Certifier } from '@app/land-hold/certifier/models/Certifier.ts';
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';
import { ApprovalAuthority } from '@app/land-hold/approval-authority/models/ApprovalAuthority';
import { Project } from '@app/land-hold/project/models/Project';
import { Bundle } from '@app/purchase/bundle/models/Bundle.ts';
import { Payout } from '@app/purchase/payout/models/Payout.ts';

const { isEmptyArray } = useUtility();
const theme = useTheme();
const page = usePage();
// Array of menu items that are opened by default. e.g., ['Transactions']
const open = ref([]);
const drawer = ref(true);
const publicRegistryFontStyle = computed(() => ({
    backgroundColor: theme.global.current.value.colors.primary + ' !important',
}));

// Submenu inside another submenu example
// const admins = [
//     ['Management', 'mdi-account-multiple-outline'],
//     ['Settings', 'mdi-cog-outline'],
// ];
interface INavItem {
    title: string;
    icon?: string;
    to?: string;
    active: boolean;
}

type ChildNavItem = INavItem;

interface IParentNavItem extends INavItem {
    active: boolean;
    children: ChildNavItem[];
}

function activateItem(item: IParentNavItem | ChildNavItem) {
    items.forEach((parent) => {
        parent.active = parent === item;
        parent.children.forEach((child) => {
            child.active = child === item;
        });
    });
}

function logout() {
    useAuthenticationRepository().command.logout();
}

const Dashboard = reactive<IParentNavItem>({
    title: 'Analytics',
    icon: 'mdi-chart-line',
    to: useAnalyticsRepository().query.show().endpoint,
    active: page.url === '/',
    children: [],
});

const Clients = reactive<IParentNavItem>({
    title: 'Clients',
    icon: 'mdi-domain',
    to: useRoute().build(Client.routes().index),
    active: false,
    children: [],
});

const Certifiers = reactive<IParentNavItem>({
    title: 'Certificate Owners',
    icon: 'mdi-certificate',
    to: useRoute().build(Certifier.routes().index),
    active: false,
    children: [],
});

const Transactions = reactive<IParentNavItem>({
    title: 'Transactions',
    icon: 'mdi-handshake',
    to: useRoute().build(Transaction.routes().index),
    active: false,
    children: [],
});

const Inventories = reactive<IParentNavItem>({
    title: 'Inventories',
    icon: 'mdi-archive',
    active: false,
    children: [
        {
            title: 'Inventory Management',
            to: useRoute().build(Inventory.routes().management.properties.index),
            active: false,
        },
        {
            title: 'Inventory Index',
            to: useRoute().build(Inventory.routes().index),
            active: false,
        },
        {
            title: 'Inventory Queue',
            to: useRoute().build(InventoryQueue.routes().index),
            active: false,
        },
    ],
});

const Blocks = reactive<IParentNavItem>({
    title: 'Blocks',
    icon: 'mdi-toy-brick',
    to: useRoute().build(Block.routes().index),
    active: false,
    children: [],
});

const Projects = reactive<IParentNavItem>({
    title: 'Projects',
    icon: 'mdi-folder-open',
    to: useRoute().build(Project.routes().index),
    active: false,
    children: [],
});

const Properties = reactive<IParentNavItem>({
    title: 'Properties',
    icon: 'mdi-flag',
    to: useRoute().build(Property.routes().index),
    active: false,
    children: [],
});

const Partitions = reactive<IParentNavItem>({
    title: 'Partitions',
    icon: 'mdi-collage',
    to: useRoute().build(Partition.routes().index),
    active: false,
    children: [],
});

const Owners = reactive<IParentNavItem>({
    title: 'Owners',
    icon: 'mdi-account-key',
    to: useRoute().build(Owner.routes().index),
    active: false,
    children: [],
});


const Bundles = reactive<IParentNavItem>({
    title: 'Bundles',
    icon: 'mdi-package',
    to: useRoute().build(Bundle.routes().index),
    active: false,
    children: [],
});

const Payouts = reactive<IParentNavItem>({
    title: 'Payouts',
    icon: 'mdi-cash',
    to: useRoute().build(Payout.routes().index),
    active: false,
    children: [],
});

const Users = reactive<IParentNavItem>({
    title: 'Users',
    icon: 'mdi-account-circle',
    to: useRoute().build(User.routes().index),
    active: false,
    children: [],
});

const Configuration = reactive<IParentNavItem>({
    title: 'Configuration',
    icon: 'mdi-cog',
    active: false,
    children: [
        {
            title: 'Stewards',
            to: useRoute().build(Steward.routes().index),
            active: false,
        },
        {
            title: 'States',
            to: useRoute().build(State.routes().index),
            active: false,
        },
        {
            title: 'Countries',
            to: useRoute().build(Country.routes().index),
            active: false,
        },
        {
            title: 'Approval Authorities',
            to: useRoute().build(ApprovalAuthority.routes().index),
            active: false,
        },
        {
            title: 'Landuse Factors',
            to: useRoute().build(LandUseFactor.routes().index),
            active: false,
        },
        {
            title: 'Landuse Types',
            to: useRoute().build(LandUseType.routes().index),
            active: false,
        },
        {
            title: 'Transaction Types',
            to: useRoute().build(TransactionType.routes().index),
            active: false,
        },
        {
            title: 'Inventory Types',
            to: useRoute().build(InventoryType.routes().index),
            active: false,
        },
    ],
});

const items = [Dashboard, Clients, Certifiers, Transactions, Inventories, Blocks, Projects, Properties, Partitions, Owners, Bundles, Payouts, Users, Configuration];
</script>
