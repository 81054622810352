import { Model } from '@shared/models/Model';
import type { ChipConfig, DataTableHeaders, DotNotationOf } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import { routes, type IndexPayoutIncludes, type AllPayoutIncludes } from '@app/purchase/payout/routes/routes';
import type { ValuesOfConst } from '@shared/types/ApiResponse.ts';
import { useBlockRepository } from '@app/purchase/block/repositories/useBlockRepository.ts';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository.ts';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository.ts';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository.ts';
import { useOwnerRepository } from '@app/land-hold/owner/repositories/useOwnerRepository.ts';
import { Bundle } from '@app/purchase/bundle/models/Bundle.ts';
import type { CsvStructure } from '@shared/composables/useDownloadCsv.ts';

const PayoutStatus = {
    Pending: 'pending',
    Issued: 'issued',
    Completed: 'completed',
} as const;

export interface IPayout extends IModel {
    block_id: string;
    status: ValuesOfConst<typeof PayoutStatus>;
    amount: number;
    price: number;
    currency: string;
    note: string;
}

export class Payout extends Model implements IPayout {
    block_id!: string;
    status!: ValuesOfConst<typeof PayoutStatus>;
    amount!: number;
    price!: number;
    currency!: string;
    note!: string;

    public constructor(o: IPayout) {
        super(o);
        Object.assign(this, o);
    }

    public static override chipConfig(field: DotNotationOf<AllPayoutIncludes>, item: AllPayoutIncludes): ChipConfig {
        let color;
        switch (field) {
            case 'status':
                color = Payout.getStatusColor(item.status);
                return { size: 'small', color, text: item.status };
            case 'bundle.data.status':
                if (item.bundle) {
                    color = Bundle.getStatusColor(item.bundle.data.status);
                    return { size: 'small', color, text: item.bundle.data.status };
                }
                return { size: 'small' };
            default:
                return { size: 'small' };
        }
    }

    public static override chips(): DotNotationOf<AllPayoutIncludes>[] {
        return ['status', 'bundle.data.status'];
    }

    public static getStatusColor(status: ValuesOfConst<typeof PayoutStatus>): string {
        switch (status) {
            case PayoutStatus.Issued:
                return 'info';
            case PayoutStatus.Pending:
                return 'grey';
            case PayoutStatus.Completed:
                return 'success';
            default:
                return 'grey';
        }
    }

    public static statuses(): string[] {
        return Object.values(PayoutStatus);
    }

    public static override headers(): DataTableHeaders<IndexPayoutIncludes> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Block Serial Code', key: 'block.data.block_serial_code', sortable: false, link: (item) => useBlockRepository().query.show(item.block_id).endpoint },
            { title: 'Transaction Code', key: 'transaction.data.transaction_code', sortable: false, link: (item) => useTransactionRepository().query.show(item.transaction.data.id).endpoint },
            { title: 'Owner', key: 'owner.data.name', sortable: false, link: (item) => useOwnerRepository().query.show(item.owner.data.id).endpoint },
            { title: 'Status', key: 'status' },
            { title: 'Total', key: 'amount' },
            { title: 'Currency', key: 'currency', sortable: false },
            { title: 'Carbon', key: 'block.data.carbon_block_tco2e', sortable: false },
            { title: 'Payout', key: 'price' },
            { title: 'Note', key: 'note', sortable: false },
            { title: 'Bundle', key: 'bundle.data.name', sortable: false },
            { title: 'Bundle Status', key: 'bundle.data.status', sortable: false },
            { title: 'Bundle Date', key: 'bundle.data.date', date: true, sortable: false },
            { title: 'Property Code', key: 'property.data.property_short_code', sortable: false, link: (item) => usePropertyRepository().query.show(item.property.data.id).endpoint },
            { title: 'Inventory Code', key: 'inventory.data.inventory_code', sortable: false, link: (item) => useInventoryRepository().query.show(item.inventory.data.id).endpoint },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static csvHeaders(): CsvStructure[] {
        return Payout.headers()
            .filter((header) => header.key !== 'actions')
            .map((header) => ({ title: header.title, key: header.key })) as CsvStructure[];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
