<template>
    <DataTable
        title="Bundles"
        :headers="Bundle.headers()"
        :model-class="Bundle"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Bundle',
            updateDialogBtnText: 'Update Bundle',
            deleteDialogBtnText: 'Delete Bundle',
            showCreateAction: true,
            showUpdateAction: true,
            showDeleteAction: true,
        }"
        :config="useConfig().bundle"
    >
        <template #create-action-dialog="{ item }">
            <CreateBundleDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateBundleDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteBundleDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Bundle } from '@app/purchase/bundle/models/Bundle';
import { useConfig } from '@shared/composables/useConfig';

const page = Bundle.routes().index.page;
</script>
